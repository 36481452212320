@import "/colors";

nav, .social-links {
  text-align: right;
}

nav a, .social-links a, .dropdown span {
  text-decoration: none;
  padding: 0px 3px;
}

@media only screen and (max-width: 1023px) {
  nav, .social-links {
    text-align: left;
    font-size: 1em;
  }

  nav a {
    display: block;
  }

  nav a, nav span, .social-links {
    padding: 0 5px;
  }

  .nav-main {
    display: block!important;
  }

  .nav-second a {
  padding: 17px 10px;
}
}

.nav-main {
  margin: 0 50px;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}

nav a:hover, nav span:hover, .social-links a:hover {
  text-decoration: underline;
}

.nav-second {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.nav-second a {
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  padding: 17px 0;
}

.nav-second a:hover {
  text-decoration: none;
  background-color: #FDE68A;
  color: #000;
}

a.dropdown-item {
  text-align: left;
}

.dropdown {
  vertical-align: middle;
}

bridgetown-search-form input {
  padding: 0 10px;
  margin-left: 10px;
  width: 150px;
}