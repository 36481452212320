@import "/colors";

.btn {
  @apply py-2 px-4 rounded;
  text-decoration: none!important;
}

.btn-outline-yellow {
  border: 1px solid #FDE68A;
  color: #fff;
}

.btn-outline-yellow:hover,  {
  background-color: #FDE68A;
}

.btn-yellow {
  border: 1px solid #FDE68A;
  background-color: #FDE68A;
  color: #000;
}

.btn-yellow:hover {
  border: 1px solid #FDE68A;
  background-color: $orange;
  color: #000;
}

.btn-telo {
  border: 1px solid $violet;
  color: $violet;
}

.btn-telo:hover {
  background-color: $violet;
  color: #fff;
}

.btn-identita {
  border: 1px solid black;
  color: black;
}

.btn-identita:hover {
  background-color: grey;
  color: #fff;
}

.btn-sex {
  border: 1px solid $green;
  color: $green;
}

.btn-sex:hover {
  background-color: $green;
  color: #fff;
}

.btn-zdravie {
  border: 1px solid $yellow;
  color: $yellow;
}

.btn-zdravie:hover {
  background-color: $yellow;
  color: #fff;
}

.btn-vztahy {
  border: 1px solid $orange;
  color: $orange;
}

.btn-vztahy:hover {
  background-color: $orange;
  color: #fff;
}

