@import "~bulma/bulma";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "/colors";
@import "/navigation";
@import "/header";
@import "/buttons";
@import "/table";


body {
  background: $body-background;
  color: $body-color;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  margin: 0 auto;
}

.page main, .post main {
  margin: 1rem;
}

@media only screen and (min-width: 600px) {
  .page main, .post main, .posts {
    margin: 4rem;
  }

  .footer {
    padding: 0 10px;
  }
}

.post img {
  display: inline-block;
  max-height: 500px;
}

h1, h2, h3 {
  font-weight: 600;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-weight: 400;
  font-size: 1.5em;
}

h4 {
  font-weight: 400;
  font-size: 1.3em;
}

h1, footer {
  text-align: center;
}

p {
  margin-bottom: 0.5em;
}

footer {
  background-color: $green;
}

p a {
  text-decoration: underline;
}

ul {
  list-style-type: square;
}

ol {
  list-style: decimal;
}

@layer components {
  .articles-title {
    @apply text-center py-2 md:flex md:place-content-around md:place-items-center;
  }
}

@layer components {
  .articles-list {
    @apply md:my-8 md:flex md:justify-around;
  }
}

.top-bar {
  padding: 15px 5px 6px;
}

.topic {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  margin: 1em;
  box-shadow: 5px 5px 8px #E1C86C;
  border-radius: 5px;
}

.topic-posts {
   width: 200px;
}

.topic-index {
  width: 300px;
}

.topic:hover {
  filter: grayscale(0.4);
}

.topic img {
  border-radius: 5px;
}

.topic h3 {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: rgba(256,256,256,0.7);
  padding: 0.5em 2em;
}

.topic-index h3 {
  bottom: 20%;
  left: 50%;
  padding: 0.5em 2em;
  font-size: 1.4em;
  border-radius: 5px;
}

.topic-posts h3 {
  bottom: 17%;
  left: 50%;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 5px;
}

.middle {
  bottom: 10%!important;
}

.important-text {
  text-align: center;
  margin: 1.5em 0;
  font-weight: bold;
}

.notes {
  background-color: $grey;
  padding: 2rem 2.5rem;
  margin: 1em 0;
}

.topic-bar {
  padding: 2rem 2.5rem;
  margin: 1em 0;
}

.list-of-articles ul {
  margin-left: 3em;
}

// Images
.team-img {
  display: inline-block;
  margin: 1em 0 3em;
  max-width: 500px;
}

.main-photo-articles {
  // max-width: 500px;
  margin: 1em 0;
}

// Colours
.f-telo {
  color: white;
  background-color: $violet;
}

.f-identita {
  border: 1px solid black;
}

.f-sex {
  color: white;
  background-color: $green;
}

.f-zdravie {
  background-color: $yellow;
}

.f-vztahy {
  background-color: $orange;
}

.box-post {
  padding: 1em;
  margin: 1em 0;
}

// Screen rules
@media only screen and (max-width: 600px) {
  .footer {
    padding: 0 10px;
  }

   h1 {
    font-size: 2.5em;
  }
}